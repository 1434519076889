import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { motion } from "framer-motion"

// Replace with your own Stripe public key
const PUBLIC_KEY = "pk_test_51MnIsOF9zYP6DsBOCK9al1tdeaCVwAoPfMpnFIffwMvGGEDoHxiFPUQcwTY4wscGPeaWwmFSntugibw3NVhYydT600PYDUMhBd";
const STRIPE_PROMISE = loadStripe(PUBLIC_KEY);

const PaymentForm = (props) => {
    const functions = getFunctions(props.app);
    const [executeCallable, executing, error] = useHttpsCallable(functions, 'createSubscriptionCheckoutSession');

    const handleSubmit = async (e) => {
        e.preventDefault();
        createSubscriptionCheckoutSession();
    };

    const createSubscriptionCheckoutSession = async () => {
        if (window.location.hostname === 'localhost') { connectFunctionsEmulator(functions, 'localhost', 5001); }
        const subscriptionCheckoutSessionRequest = await executeCallable().then((result) => { return result.data })
        console.log(subscriptionCheckoutSessionRequest)
        window.location.href = subscriptionCheckoutSessionRequest.checkout_url
    }
    if (executing) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Heading to Checkout...</h1>
            </motion.div>
        )
    }

    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="ScrollableWrapper"
        >
            <form onSubmit={handleSubmit}>
                <h1>Subscribe</h1>
                <h3>This is a Pay-As-You-Go subscription in which you are charged at the end of the month for your token usage.</h3>
                <h3>Your subscription will start with an initial $2 for 100,000 tokens when subscribing.</h3>
                <h3>Once you run out of Tokens, and if 'Auto-Refill' is enabled, you'll add another $2 to your monthly bill, until your Monthly Limit has been reached.</h3>
                <h3>You can set your Monthly Limit in Your Dashboard.</h3>
                <label><h2>Terms and Conditions</h2>
                    <textarea multiline={"true"} disabled={true} value={`Terms and Conditions for Usage-Based Subscription Service

Acceptance of Terms
By using the Usage-Based Subscription Service ("Service"), you ("User") agree to be bound by these Terms and Conditions ("Terms"). The Service is provided by [Company Name] ("Company"). The Company reserves the right to update these Terms at any time without notice to the User.

Subscription and Billing
2.1. The User agrees to pay $2 for every 100,000 usage tokens purchased. The User may choose to enable an automatic refill option, which will result in the User being automatically charged $2 for 100,000 additional tokens whenever a call to the API requires more tokens than are currently available in the User's account.

2.2. If the User chooses not to enable the automatic refill option, the User may purchase additional tokens manually by clicking the "Buy Tokens" button on the "buy-tokens" page of the Company's website or within the Company's app. Tokens will be available for use immediately upon successful payment.

2.3. The User's subscription will continue until canceled. To cancel the subscription, the User must follow the cancellation procedure specified on the Company's website or in the app. Upon cancellation, the User will be charged for any tokens purchased but not used.

2.4. Unused tokens will remain tied to the User's account and may be used as normal until they are depleted.

Token Usage and Refunds
3.1. Tokens purchased through the Service are non-refundable, except as required by law. The User agrees that once tokens have been purchased, they cannot be refunded, transferred, or exchanged for cash or any other form of compensation.

3.2. Any unused tokens at the end of the month will remain tied to the User's account and may be used as normal.

Termination
The Company reserves the right to terminate the User's access to the Service at any time, with or without cause or notice, effective immediately. Upon termination, the User will be charged for any tokens purchased but not used.

Liability
The User agrees that the Company's entire liability, and the User's exclusive remedy, for any disputes arising from the use of the Service, shall be limited to the amount the User paid for the Service during the most recent billing period.

Governing Law
These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the Company is incorporated, without regard to its conflict of law provisions.

Severability
If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.

Entire Agreement
These Terms constitute the entire agreement between the User and the Company with respect to the subject matter hereof and supersede and replace all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter. Any waiver of any provision of these Terms will be effective only if in writing and signed by the Company.`}>
                    </textarea>
                </label>
                <label className='tacCheckbox'>
                    <h2>I Agree To the Terms and Conditions</h2>
                    <input type="checkbox" required />
                </label>
                <button className='DashboardButton'>Checkout</button>
            </form>
        </motion.div>
    );
};

const MeteredSubscriptionForm = (props) => {
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const idempotency_key = Math.random().toString(36).substring(7);
    const buyTokens = async () => {
        setProcessing(true);
        const functions = getFunctions(props.app);
        // connectFunctionsEmulator(functions, 'localhost', 5001); //Remove this line when deploying

        // Calls the startSubscription cloud function
        // This is a function that creates a customer token 
        // and adds that token and payment method id to the user's document in the database
        const purchaseTokens = httpsCallable(functions, 'purchaseTokens');
        purchaseTokens({ idempotency_key: idempotency_key })
            .then((result) => {
                const data = result.data;
                console.log(data.message);
                if (data.status = "success") {
                    setProcessing(false);
                    setSucceeded(true);
                }
            })
            .catch((error) => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log("there was an error in the firebase function call")
                console.log(code, message, details);
            });
    }
    return (
        <Elements stripe={STRIPE_PROMISE}>
            <PaymentForm loading={props.loading} navigate={props.navigate} user={props.user} app={props.app} />
        </Elements>
    );
};

export default MeteredSubscriptionForm;
