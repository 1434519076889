import React, { useEffect, useState } from 'react';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { motion } from "framer-motion"
import "../styles/Dashboard.css";
import { NavLink } from 'react-router-dom';


function TransactionHistory(props) {
    const functions = getFunctions(props.app);
    const [transactionHistoryExists, setTransactionHistoryExists] = useState(false);
    const [getTransactionHistory, executingGetTransactionHistory, getTransactionHistoryError] = useHttpsCallable(functions, 'getUserTransactionHistory');
    const [transactionHistory, setTransactionHistory] = useState(null);
    useEffect(() => {
        if (!executingGetTransactionHistory) {
            if (window.location.hostname === "localhost") {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            getTransactionHistory().then((result) => {
                console.log(result.data)
                if (result.data.invoiceList.filter((item) => item.amount_due !== 0).length > 0 ||
                    result.data.checkoutSessions.filter((item) => item.amount !== 0).length > 0) {
                    setTransactionHistoryExists(true);
                }
                setTransactionHistory(result.data);
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])
    if (!executingGetTransactionHistory && !transactionHistoryExists) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Transaction History</h1>
                <h2>Looks Like You Have No Transaction History</h2>
                <NavLink to="/dashboard" className="DashboardButton">Back To Dashboard</NavLink>
            </motion.div>
        )
    }
    if (!executingGetTransactionHistory && transactionHistory !== null) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="ScrollableWrapper"
            >
                {/* Print out the transaction history array using array.map */}
                <h1>Transaction History</h1>
                {(transactionHistory.invoiceList.filter((item) => item.amount_due !== 0).length > 0) && <h2>Subscription Transactions</h2>}
                {transactionHistory.invoiceList.map((item) => {
                    // Table for the following item properties:
                    // amount_due, amount_paid, amount_remaining, billing_reason, created (*1000 toLocaleDateString)
                    if (item.amount_due === 0) return null;
                    return (
                        <table key={item.id}>
                            <thead>
                                <tr>
                                    <th><span>{new Date(item.lines.data[0].period.start * 1000).toLocaleTimeString()}</span></th>
                                    <th><span>{new Date(item.lines.data[0].period.end * 1000).toLocaleTimeString()}</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span>Amount Due</span></td>
                                    <td><span>${item.amount_due / 100}</span></td>
                                </tr>
                                <tr>
                                    <td><span>Amount Paid</span></td>
                                    <td><span>${item.amount_paid}</span></td>
                                </tr>
                                <tr>
                                    <td><span>Amount Remaining</span></td>
                                    <td><span>${item.amount_remaining / 100}</span></td>
                                </tr>
                                {item.hosted_invoice_url &&
                                    <tr>
                                        <td><span>Invoice Link</span></td>
                                        <td><span><NavLink to={item.hosted_invoice_url}>Visit</NavLink></span></td>
                                    </tr>
                                }
                                {item.billing_reason === "subscription_create" && <tr>
                                    <td>
                                        <span>
                                            Billing Cycle Start
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {new Date(item.lines.data[0].period.start * 1000).toLocaleDateString()}
                                        </span>
                                    </td>
                                </tr>
                                }
                                {item.billing_reason === "subscription_create" && <tr>
                                    <td>
                                        <span>
                                            Billing Cycle End
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {new Date(item.lines.data[0].period.end * 1000).toLocaleDateString()}
                                        </span>
                                    </td>
                                </tr>}
                            </tbody>
                        </table>
                    )
                })}
                {transactionHistory.checkoutSessions.filter((item) => item.amount_total !== 0).length > 0 && <h2>Token Purchase Transactions</h2>}
                {
                    transactionHistory.checkoutSessions.map((item) => {
                        if (item.amount_total === 0) return null;
                        return (
                            <table key={item.id}>
                                <thead>
                                    <tr>
                                        <th><span>{new Date(item.created * 1000).toLocaleDateString()}</span></th>
                                        <th><span>{new Date(item.created * 1000).toLocaleTimeString('en-us')}</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><span>Tokens Purchased</span></td>
                                        <td><span>{((item.amount_total / 100) * 100000).toLocaleString()}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span>Total (USD)</span></td>
                                        <td>
                                            <span>${(item.amount_total / 100).toFixed(2)}</span></td>
                                    </tr>
                                </tbody>
                            </table>

                        )
                    })
                }
                <NavLink to="/dashboard" className="DashboardButton">Back To Dashboard</NavLink>
            </motion.div>
        )
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <h1>Loading Transaction History...</h1>
        </motion.div>
    )
}

export default TransactionHistory;