import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import { NavLink, useNavigate } from 'react-router-dom';
import { BrowserView } from 'react-device-detect';

function TokenPurchaseSuccess(props) {
    const OpenChimeraApp = () => {
        console.log("Opening new tab to arizona://tokensPurchased")
        window.open("arizona://tokensPurchased", "_blank", 'noreferrer');
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <div>
                <h1>Token Purchase Was Successful!</h1>
                <h3>Within Seconds You should see your token balance updated accordingly.</h3>
                <BrowserView>
                    <button onClick={OpenChimeraApp} className="DashboardButton">Open Chimera</button>
                    <br />
                </BrowserView>
                <NavLink to="/dashboard" className="DashboardButton">Back To Dashboard</NavLink>
            </div>

        </motion.div>
    );
}

export default TokenPurchaseSuccess;