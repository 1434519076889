// This is a Component for a page that handles managing subscription through the stripe api with firebase storage
// There should be a button that allows the user to cancel their subscription, it will call the cancelSubscription function

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from "framer-motion"
import { getFirestore, doc } from 'firebase/firestore';
import "../styles/Dashboard.css";
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { useHttpsCallable } from "react-firebase-hooks/functions";

function Dashboard(props) {
    useEffect(() => {
        // Get any query params from the url
        const params = new URLSearchParams(window.location.search)
        // if the query params contains 'email', save it to local storage
        if (params.has('email')) {
            localStorage.setItem('emailForSignIn', params.get('email'))
        }
    }, [])
    // If the props.user is null, redirect to the homepage at "/"
    if (!props.user) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Logging Out...</h1>
            </motion.div>
        )
    }
    return (
        <UserDashboard user={props.user} app={props.app} />
    )

}

function UserDashboard(props) {
    const functions = getFunctions(props.app)
    const [executeCallable, executing, error] = useHttpsCallable(functions, 'getUserDataCallable');
    // const [userData, documentLoading, documentError] = useDocumentOnce(
    //     doc(getFirestore(props.app), 'account-data', props.user.uid)
    // );
    const [documentError, setDocumentError] = useState(false)
    const [userData, setUserData] = useState(null);

    // print user data once it loads
    useEffect(() => {
        if (!userData && !executing && !error) {
            if (window.location.hostname === 'localhost') { connectFunctionsEmulator(functions, 'localhost', 5001); }
            executeCallable().then((result) => {
                console.log(result)
                if (result === undefined) {
                    console.log("result is undefined")
                    setDocumentError(true)
                    return
                }
                setUserData(result.data)
            })
        }

    }, [userData, executing, error])

    if (executing || (!userData && !documentError && !error)) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Loading Your Data...</h1>
            </motion.div>
        )
    }
    if (error || !userData || documentError) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <div>
                    <h1>Something Went Wrong Trying to Load Your Data</h1>
                    <h3>Please Try Again Later</h3>
                </div>
            </motion.div>)
    } if (!userData.token_balance && !userData.subscription) { //Has never bought tokens
        return <FreeUserDashboard userData={userData} hasPaymentHistory={false} user={props.user} app={props.app} />
    }
    if (!userData.subscription_id) { //Has bought tokens but is not subscribed
        return <FreeUserDashboard userData={userData} hasPaymentHistory={true} user={props.user} app={props.app} />
    }

    return ( // If user is subscribed, show the subscribed dashboard
        <SubscribedUserDashboard userData={userData} user={props.user} app={props.app} />
    )
}

function FreeUserDashboard(props) {
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <div>
                {props.userData.token_balance > 0 && <h1>You have<br />{props.userData.token_balance.toLocaleString()}<br />Tokens</h1>}
                {props.userData.token_balance > 0 && <h3>Head to the Chimera App to Use 'em!</h3>}
                {!props.userData.token_balance && <h1>Get some Tokens to Access Chat-GPT, Dall-E, Speech To Text, Text to Speech, and more!</h1>}
                <div className="LineBreak"></div>
                <div className='TokenUsageButtons'>
                    <NavLink to="/buy-tokens" className="DashboardButton">{(props.userData.token_balance > 0) ? "Get More Tokens" : "Get Tokens (One Time Purchase)"}</NavLink>
                    <NavLink to="/subscribe" className="DashboardButton">Go Monthly</NavLink>
                    <div className="LineBreak"></div>

                    <NavLink to="/" className="DashboardButton">How Can I Spend Tokens?</NavLink>
                    <div className="LineBreak"></div>

                    <NavLink className='DashboardButton' to='https://google.com'>Download Chimera For Free</NavLink>
                    <div className="LineBreak"></div>
                    <NavLink className='DashboardButton' to='/transaction-history'>Transaction History</NavLink>
                    <NavLink className='DashboardButton' to='/change-password'>Change Password</NavLink>
                    <NavLink className='DashboardButton' to='/contact-support'>Contact Support</NavLink>
                    {/* <button className='DashboardButton' onClick={() => { goToCustomerPortal() }}>Manage Subscription</button> */}

                </div>
            </div>
        </motion.div>
    )
}

function SubscribedUserDashboard(props) {
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="ScrollableWrapper"
        >
            {props.user && props.app && <TokenUsageData userData={props.userData} user={props.user} app={props.app} />}
        </motion.div>

    )
}

function TokenUsageData(props) {
    const functions = getFunctions(props.app);
    const [setAutoRefill, executingSetAutoRefill, errorSetAutoRefill] = useHttpsCallable(functions, 'setAutoRefill');
    const [createCustomerPortalSession, executingCreateCustomerPortalSession, errorCreateCustomerPortalSession] = useHttpsCallable(functions, 'createCustomerPortalSession');

    const goToCustomerPortal = async () => {
        if (window.location.hostname === 'localhost') { connectFunctionsEmulator(functions, 'localhost', 5001); }
        const result = await createCustomerPortalSession({ uid: props.user.uid });
        window.location.href = result.data.portal_url;
    }
    const callSetAutoRefill = async () => {
        if (window.location.hostname === 'localhost') { connectFunctionsEmulator(functions, 'localhost', 5001); }
        const result = await setAutoRefill({ new_auto_refill_value: (props.userData.auto_refill_enabled) ? "disable" : "enable" });
        console.log(result)
        window.location.reload();
    }
    const periodStart = () => {
        let date = new Date(props.userData.subscription.current_period_start * 1000);

        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var month = months[date.getMonth()];

        var day = date.getDate();
        var suffix = '';
        switch (day % 10) {
            case 1: suffix = (day === 11) ? 'th' : 'st'; break;
            case 2: suffix = (day === 12) ? 'th' : 'nd'; break;
            case 3: suffix = (day === 13) ? 'th' : 'rd'; break;
            default: suffix = 'th'; break;
        }

        var year = date.getFullYear();

        let dateString = month + ' ' + day + suffix + ', ' + year;

        return dateString;
    }
    // Billing date is one month from the start date
    const billingDate = () => {
        let date = new Date(props.userData.subscription.current_period_end * 1000);

        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var month = months[date.getMonth()];

        var day = date.getDate();
        var suffix = '';
        switch (day % 10) {
            case 1: suffix = (day === 11) ? 'th' : 'st'; break;
            case 2: suffix = (day === 12) ? 'th' : 'nd'; break;
            case 3: suffix = (day === 13) ? 'th' : 'rd'; break;
            default: suffix = 'th'; break;
        }

        var year = date.getFullYear();

        let dateString = month + ' ' + day + suffix + ', ' + year;

        return dateString;
    }
    if (executingSetAutoRefill) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Updating Your Auto-Refill Preferences</h1>
            </motion.div>
        )
    }
    if (executingCreateCustomerPortalSession) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Navigating To Customer Portal</h1>
            </motion.div>
        )
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="TokenUsageDataContainer"
        >
            <h1>Your Account</h1>
            <table>
                <tbody>
                    <tr>
                        <td><span>Billing Start</span></td>
                        <td><span>{periodStart()}</span></td>
                    </tr>
                    <tr>
                        <td><span>Token Balance</span></td>
                        <td><span>{props.userData.token_balance.toLocaleString()} Tokens</span></td>
                    </tr>
                    {(props.userData.upcomingInvoice) ? <tr>
                        <td><span>Tokens Purchased Automatically This Month</span></td>
                        <td><span>{(props.userData.upcomingInvoice.lines.data[0].quantity * 100000).toLocaleString()} Tokens</span></td>
                    </tr>
                        : null}

                    <tr>
                        <td><span>Tokens Spent</span></td>
                        <td><span>{((props.userData.upcomingInvoice.lines.data[0].quantity * 100000) - props.userData.token_balance).toLocaleString()} Tokens</span></td>
                    </tr>
                    <tr>
                        <td><span>Spending Limit</span></td>
                        <td><span>${props.userData.max_tokens_per_month / 50000}/month</span></td>
                    </tr>
                    <tr>
                        <td><span>Auto Refill</span></td>
                        <td><span>{(props.userData.auto_refill_enabled) ? "Enabled" : "Disabled"}</span></td>
                    </tr>
                    <tr>
                        <td><span>Estimated Next Bill</span></td>
                        <td><span>${props.userData.upcomingInvoice.lines.data[0].quantity * 2}</span></td>
                    </tr>
                    <tr>
                        <td className='BillingDateTD'><span>Next Billing Date</span></td>
                        <td className='BillingDateTD'>
                            <span>{billingDate()}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="LineBreak"></div>
            <div className="TokenUsageButtons">
                <NavLink to="/" className="DashboardButton">How Can I Spend Tokens?</NavLink>
                <div className="LineBreak"></div>
                {props.userData.subscription_id && <button className='DashboardButton' onClick={() => { callSetAutoRefill() }}>{(props.userData.auto_refill_enabled) ? "Disable Auto-Refill" : "Enable Auto-Refill"}</button>}
                {props.userData.subscription_id && <NavLink className='DashboardButton' to='/spending-limit'>Change Your Spending Limit</NavLink>}
                {props.userData.subscription_id && <button className='DashboardButton' onClick={() => { goToCustomerPortal() }}>Manage Subscription</button>}
                <NavLink className='DashboardButton' to='/transaction-history'>Transaction History</NavLink>
                <div className="LineBreak"></div>
                {props.userData.subscription_id && <NavLink className='DashboardButton' to='/change-password'>Change Password</NavLink>}
                {props.userData.subscription_id && <NavLink className='DashboardButton' to='/contact-support'>Contact Support</NavLink>}
                <div className="LineBreak"></div>
                {props.userData.subscription_id && <NavLink className='DashboardButton' to='https://google.com'>Download Chimera For Free</NavLink>}
            </div>
        </motion.div>
    )
}




export default Dashboard;