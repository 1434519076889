import { useState, useEffect } from 'react';
import MenuImage from "../res/menu.png";
import '../App.css';
import { NavLink } from 'react-router-dom';

function Navbar(props) {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }
    useEffect(() => {
        setShowMenu(false);
    }, [props.location])
    return (
        <div className="Navbar">
            <PopoutMenu navigate={props.navigate} location={props.location} logout={props.logout} user={props.user} showMenu={showMenu} />
            <div className={showMenu ? "MenuIcon MenuActive" : "MenuIcon"} onClick={toggleMenu}><img alt="Menu Icon" src={MenuImage}></img></div>
        </div>
    )
}

function PopoutMenu(props) {
    return (
        <div className={props.showMenu ? "PopoutMenu ShowMenu" : "PopoutMenu"}>
            <h1>Chimera</h1>
            {props.user && <h2>Hey, {props.user.email.substring(0, props.user.email.indexOf("@"))}</h2>}
            <div className="PopoutMenuButtonsWrapper">
                <NavLink className={(props.location.pathname === "/") ? "PopoutLink ActiveLink" : "PopoutLink"} id="HomeLink" to="/">Home</NavLink>
                {!props.user && <NavLink className={(props.location.pathname === "/login") ? "PopoutLink ActiveLink" : "PopoutLink"} id="LoginLink" to="/login">Login</NavLink>}
                {!props.user && <NavLink className={(props.location.pathname === "/signup") ? "PopoutLink ActiveLink" : "PopoutLink"} id="RegisterLink" to="/signup">Sign Up</NavLink>}
                {props.user && <NavLink className={(props.location.pathname === "/dashboard") ? "PopoutLink ActiveLink" : "PopoutLink"} to="/dashboard">Dashboard</NavLink>}
                {props.user && <NavLink className="PopoutLink" id="LogoutLink" onClick={()=>{props.logout();}}>Logout</NavLink>}
            </div>
        </div>
    )
}

export default Navbar;