import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import { NavLink, useNavigate } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
function SubscriptionSuccess(props) {
    const OpenChimeraApp = () => {
        console.log("Opening new tab to arizona://tokensPurchased")
        window.open("arizona://tokensPurchased", "_blank", 'noreferrer');
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <div>
                <h1>You're Now Subscribed to Chimera's Pay-As-You-Go Plan</h1>
                <h3>Tokens will automatically be added to your account in $2 (100,000 Token) increments when you need them.</h3>
                <BrowserView>
                    <button onClick={OpenChimeraApp} className="DashboardButton">Open Chimera</button>
                    <br />
                </BrowserView>
                <NavLink to="/dashboard" className="DashboardButton">Back To Dashboard</NavLink>
            </div>

        </motion.div>
    );
}

export default SubscriptionSuccess;