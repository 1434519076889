import React from "react";
import { useState, useEffect } from "react";
import '../App.css';
import { motion } from "framer-motion"
import { getAuth, signOut } from 'firebase/auth';
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
function Login(props) {
    const [emailForSignIn, setEmailForSignIn] = useState("");
    useEffect(() => {
        // Check for local storage "emailForSignIn"
        setEmailForSignIn(localStorage.getItem("emailForSignIn"))
        if (props.user) {
            props.navigate("/dashboard");
        }
    }, []);
    return (
        <LoginModal emailForSignIn={emailForSignIn} navigate={props.navigate} login={props.login} logout={props.logout} auth={props.auth} />
    );
}

function LoginModal(props) {
    const [email, setEmail] = useState(props.emailForSignIn ? props.emailForSignIn : "");
    const [password, setPassword] = useState("");
    const [showModal, setShowModal] = useState(true);
    const [loginError, setLoginError] = useState(" ");
    const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(props.auth);
    let modalClassName = "LoginModal";
    const handleRegister = () => {
        props.navigate("/signup");
    }
    useEffect(() => {
        if (user) {
            setTimeout(() => {
                props.navigate("/dashboard");
            })
        }
    }, [user, props])
    useEffect(()=>{
        if (error) {
            console.log(error)
            // Get the error type, which is in parentheses, and handle them in a switch statement
            // The possible error types are Auth/Too-Many-Requests, Auth/Wrong-Password
            let errorType = error.message.match(/\(([^)]+)\)/)[1];
            switch (errorType) {
                case "Auth/Too-Many-Requests":
                    setLoginError("Too many requests. Please try again later.");
                    break;
                case "Auth/Wrong-Password":
                    setLoginError("Incorrect password.");
                    break;
                case "Auth/Invalid-Email":
                    setLoginError("Invalid email. If you believe this was an error, let us know at support@chimera-app.com.");
                    break;
                case "Auth/User-Disabled":
                    setLoginError("This account has been disabled. If you believe this was an error, let us know at support@chimera-app.com.");
                    break;
                case "Auth/User-Not-Found":
                    setLoginError("This account does not exist. If you believe this was an error, let us know at support@chimera-app.com.");
                    break;
                default:
                    setLoginError("An unknown error occurred. If you have an account, and are unable to login, let us know at support@chimera-app.com.");
                    break;
            }
            setLoginError(error.message);
        }
    }, [error])
    if (user) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Redirecting...</h1>
            </motion.div>
        )
    }
    if (loading) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Signing in...</h1>
            </motion.div>
        )
    }
    if (!user) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <div className="AuthWrapper" >
                    <h1>Login</h1>
                    <EmailInput email={email} setEmail={setEmail} />
                    <PasswordInput setPassword={setPassword} />
                    <h3 className="LoginError">{loginError}</h3>
                    <button className="LoginButton" onClick={() => { signInWithEmailAndPassword(email, password) }}>Login</button>
                    <div className="LineBreak"></div>
                    <button className="LoginButton" onClick={handleRegister}>Sign Up</button>
                </div>
            </motion.div>
        );
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <h1>Redirecting</h1>
        </motion.div>
    )

}

function EmailInput(props) {
    return (
        <div className="InputDiv">
            <input placeholder=" " className="LoginInput" id="emailField" type="text" value={props.email ? props.email : ""} onChange={(evt) => { props.setEmail(evt.target.value) }}></input>
            <label className="inputLabel" htmlFor="emailField">Email</label>
        </div>
    );
}

function PasswordInput(props) {
    return (
        <div className="InputDiv">
            <input placeholder=" " className="LoginInput" id="passwordField" type="password" onChange={(evt) => { props.setPassword(evt.target.value) }}></input>
            <label className="inputLabel" htmlFor="passwordField">Password</label>
        </div>
    );
}

export default Login;