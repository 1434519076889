import { motion } from "framer-motion"
import React, { useEffect, useState } from 'react';
import { useCollection, useDocumentOnce } from 'react-firebase-hooks/firestore'
import { doc, getFirestore } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { useHttpsCallable } from "react-firebase-hooks/functions";

function SpendingLimitPage(props) {
    const functions = getFunctions(props.app);
    const [tokenLimitUpdateSuccessful, setTokenLimitUpdateSuccessful] = useState(false);
    const [tokenLimitValue, setTokenLimitValue] = useState(2);
    const [executeCallable, executing, error] = useHttpsCallable(functions, 'setTokenLimit');
    const [userData, documentLoading, documentError] = useDocumentOnce(
        doc(getFirestore(props.app), 'account-data', props.user.uid)
    );
    const saveNewTokenLimit = () => {
        console.log("Saving new token limit")
        if (window.location.hostname === 'localhost') { connectFunctionsEmulator(functions, 'localhost', 5001); }
        executeCallable({ new_max_tokens_per_month: tokenLimitValue * 50000 }).then((result) => {
            console.log(result)
            setTokenLimitUpdateSuccessful(true);
        })
    }
    useEffect(() => {
        if (userData) {
            console.log(userData.data().max_tokens_per_month)
            setTokenLimitValue(userData.data().max_tokens_per_month / 50000)
        }
    }, [userData])
    if (executing) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Saving New Token Limit...</h1>
            </motion.div>
        )
    }
    if (tokenLimitUpdateSuccessful) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <div>
                    <h1>Token Limit Updated!</h1>
                    <button onClick={() => { props.navigate("/dashboard") }} className="DashboardButton">Back To Dashboard</button>
                </div>
            </motion.div>
        )
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <div>
                <h1>Set Your Token Usage Limits</h1>
                <p>Set a limit on how many tokens you can buy per month automatically with your subscription.</p>
                <p>Once this limit is hit, you won't automatically purchase new tokens until</p>
                <label>Monthly Spending Limit (USD)</label>
                <input min='2' max='100' step='2' value={tokenLimitValue} onChange={(e) => { setTokenLimitValue(e.target.value) }} type='range' />
                <p>${tokenLimitValue} per Month</p>
                <button onClick={saveNewTokenLimit} className="DashboardButton">Save New Token Limit</button>
                <br />
                <button onClick={() => { props.navigate("/dashboard") }} className="DashboardButton">Back To Dashboard</button>
            </div>
        </motion.div>
    )
}

export default SpendingLimitPage;
