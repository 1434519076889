import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom"
import Home from '../pages/Home'
import Login from '../pages/Login'
import Signup from "../pages/Signup"
import Dashboard from '../pages/Dashboard'
import BuyTokens from '../pages/BuyTokens'
import Subscribe from '../pages/Subscribe'
import SpotifyAuthPage from '../pages/SpotifyAuthPage'
import TokenPurchaseSuccess from '../pages/TokenPurchaseSuccess'
import SubscriptionSuccess from '../pages/SubscriptionSuccess'
import SpendingLimit from '../pages/SpendingLimit'
import ContactSupportPage from '../pages/ContactSupport'
import ChangePasswordPage from '../pages/ChangePassword'
import TransactionHistory from '../pages/TransactionHistory'
import '../index.css';
import { AnimatePresence } from 'framer-motion'

function AnimatedRoutes(props) {
    const location = useLocation();
    return (
        <AnimatePresence initial={true} mode={"wait"}>
            <Routes key={location.pathname} location={location}>
                <Route path="/" element={<Home loading={props.loading} user={props.user} navigate={props.navigate} />} />
                <Route path="/login" element={<Login user={props.user} navigate={props.navigate} logout={props.login} auth={props.auth} />} />
                <Route path="/signup" element={<Signup user={props.user} navigate={props.navigate} logout={props.login} auth={props.auth} />} />
                <Route path="/dashboard" element={<Dashboard app={props.app} userData={props.userData} user={props.user} navigate={props.navigate} logout={props.logout} />} />
                <Route path="/buy-tokens" element={<BuyTokens loading={props.loading} app={props.app} user={props.user} navigate={props.navigate} logout={props.logout} />} />
                <Route path="/subscribe" element={<Subscribe loading={props.loading} app={props.app} user={props.user} auth={props.auth} navigate={props.navigate} logout={props.logout} />} />
                <Route path="/spotifyAuth" element={<SpotifyAuthPage />} />
                <Route path="/token-purchase-success" element={<TokenPurchaseSuccess />} />
                <Route path="/subscription-success" element={<SubscriptionSuccess />} />
                <Route path="/spending-limit" element={<SpendingLimit navigate={props.navigate} user={props.user} app={props.app} />} />
                <Route path="/contact-support" element={<ContactSupportPage navigate={props.navigate}/>} />
                <Route path="/change-password" element={<ChangePasswordPage auth={props.auth} navigate={props.navigate}/>} />
                <Route path="/transaction-history" element={<TransactionHistory auth={props.auth} app={props.app} navigate={props.navigate}/>}/>
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes;