import React from "react";
import { useState, useEffect } from "react";
import '../App.css';
import { motion } from "framer-motion";
import { gAuth, etAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
function Signup(props) {
    const [createUserWithEmailAndPassword, user, loading, error] = useCreateUserWithEmailAndPassword(props.auth);
    const [waitingForSignupTrigger, setWaitingForSignupTrigger] = useState(false);
    const [signupError, setSignupError] = useState(" ");
    useEffect(() => {
        if (user) {
            setWaitingForSignupTrigger(true);
            setTimeout(() => {
                props.navigate("/dashboard");
            }, 5000)
        }
    }, [user]);
    const handleRegister = (email, password) => {
        createUserWithEmailAndPassword(email, password)
    }
    if (waitingForSignupTrigger) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Signup successful! Heading to your dashboard...</h1>
            </motion.div>
        )
    }
    if (loading) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Signing up...</h1>
            </motion.div>
        )
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="ScrollableWrapper"
        >
            <SignupModal navigate={props.navigate} login={props.login} logout={props.logout} handleRegister={handleRegister} setSignupError={setSignupError} signupError={signupError} />
        </motion.div>
    );
}

function SignupModal(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    const [showModal, setShowModal] = useState(true);
    let modalClassName = "LoginModal";
    const handleLogin = () => {
        props.navigate("/login");
    }


    return (
        // If !showModal, add class HiddenModal
        <div className="AuthWrapper">
            <h1>Sign Up</h1>
            <EmailInput setEmail={setEmail} />
            <PasswordInput setPassword={setPassword} />
            <ConfirmPasswordInput setConfirmPassword={setConfirmPassword} />
            <p className="SignupError">{props.signupError}</p>
            <button className="LoginButton" onClick={() => {
                if (password !== confirmPassword) {
                    props.setSignupError("Passwords do not match");
                    return;
                }
                props.handleRegister(email, password);
            }}>Confirm Signup</button>
            <div className="LineBreak"></div>
            <button className="LoginButton" onClick={handleLogin}>Login</button>
        </div>
    );
}

function EmailInput(props) {
    return (
        <div className="InputDiv">
            <input placeholder=" " className="LoginInput" id="emailField" type="text" onChange={(evt) => { props.setEmail(evt.target.value) }}></input>
            <label className="inputLabel" htmlFor="emailField">Email</label>
        </div>
    );
}

function PasswordInput(props) {
    return (
        <div className="InputDiv">
            <input placeholder=" " className="LoginInput" id="passwordField" type="password" onChange={(evt) => { props.setPassword(evt.target.value) }}></input>
            <label className="inputLabel" htmlFor="passwordField">Password</label>
        </div>
    );
}

function ConfirmPasswordInput(props) {
    return (
        <div className="InputDiv">
            <input placeholder=" " className="LoginInput" id="confirmPasswordInput" type="password" onChange={(evt) => { props.setConfirmPassword(evt.target.value) }}></input>
            <label className="inputLabel" htmlFor="confirmPasswordInput">Confirm Password</label>
        </div>
    );
}

export default Signup;