import { motion } from "framer-motion"
import React, { useEffect, useState } from 'react';
function ContactSupportPage(props) {
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <div>
                <h1>Contact Support</h1>
                <p>For any issues with payment or all your support needs, please email<br /><a href="mailto:support@chimera-app.com">support@chimera-app.com</a></p>
                <button onClick={() => { props.navigate("/dashboard") }} className="DashboardButton">Back To Dashboard</button>
            </div>
        </motion.div>
    )
}
export default ContactSupportPage;