import React, { useEffect, useState } from "react";
import '../App.css';
import '../styles/Home.css';
import { motion } from "framer-motion"
import videoBG from "../res/a.mp4"
import demoVideoMov from "../res/DemoVideoSilent.mov"
import demoVideo from "../res/DemoVideoSilent.mp4"
import { NavLink } from 'react-router-dom';
function Home(props) {
  // Use effect when component mounts
  return (
    <motion.div
      initial={{ filter: "blur(10px)", opacity: 0 }}
      animate={{ filter: "blur(0px)", opacity: 1 }}
      exit={{ filter: "blur(10px)", opacity: 0 }}
      transition={{ duration: 1, type: "easeInOut" }}
      className="ScrollableWrapper"
    >
      <WelcomePage user={props.user}/>
    </motion.div>
  );
}
function WelcomePage(props) {
  return (
    <div className="welcomePage">
      <div className="heroElement">
        <div className="heroText">
          <h1>Chimera</h1>
          <h2>AI For All</h2>
        </div>
        <video className="demoVideo" playsInline autoPlay muted loop>
          <source src={demoVideo} type="video/mp4" />
          <source src={demoVideoMov} type="video/mov" />
        </video>
        <div className="heroElementGradient"></div>
        <video className="heroBackgroundVideo" autoPlay muted loop>
          <source src={videoBG} type="video/mp4" />
        </video>
      </div>
      <div className='aboutSection'>
        <h2>Chimera brings Chat-GPT to your desktop, and The Forge is your hub for the latest advancements in AI, all in one neat little package.</h2>
        <h2 className="featureSectionHeader">Free Features</h2>
        <h3 className='featureSectionDescription'>Enjoy locally-ran AI models for free. More coming soon.</h3>
        <div className="featuresWrapper">
          <div className="premiumFeature">
            <h3>Stable Diffusion</h3>
            <p>Use Previous Generation Image Generation Tech to create beautiful AI generated images.<br /><u>Performance
              will vary based on your PC's Hardware</u></p>
          </div>
          <div className="premiumFeature">
            <h3>Create Wallpapers from Any Image</h3>
            <p>Generate full desktop wallpapers using Stable Diffusion.</p>
          </div>
          <div className="premiumFeature">
            <h3>Spotify Playback Control</h3>
            <p>Chimera becomes a beautiful Spotify Mini-Player.<br /><u>Spotify Premium Required</u></p>
          </div>
        </div>
        <h2 className="featureSectionHeader">Premium Features</h2>
        <h3 className='featureSectionDescription'>Enjoy many more features through the use of tokens.<br />100,000 tokens is just $2.</h3>
        <div className="featuresWrapper">
          <div className="premiumFeature">
            <h3>Create Your Own AI</h3>
            <p>Choose Your Ai's name, personality, avatar, and voice to make an AI that's entirely unique.</p>
          </div>
          <div className="premiumFeature">
            <h3>Unleash the Power of Chat-GPT</h3>
            <p>Have lifelike conversations with your Ai, powered by Open AI's Chat-GPT 3. Or for a few more tokens, enjoy the legendary GPT-4.</p>
          </div>
          <div className="premiumFeature">
            <h3>Study Buddy Homework-Assistance</h3>
            <p>Knock out that essay with the power of GPT-4, the same Ai Model that passed the bar-exam in the top 99%.</p>
          </div>
          <div className="premiumFeature">
            <h3>Give Your AI A Voice</h3>
            <p>Pick from a growing list of Ai Generated Voices powered by Eleven Lab's Cutting Edge Text To Speech Engine.
            </p>
          </div>
          <div className="premiumFeature">
            <h3>Speak To Your Ai Directly</h3>
            <p>Speak to your Ai in your own voice, using Deepgram's lightning fast Speech To Text Service.</p>
          </div>
          <div className="premiumFeature">
            <h3>Unleash the Power of Dall-E 2</h3>
            <p>Create art with your new Ai. Just describe what you want to see, and watch as Open Ai's incredible Dall-E 2 paints your dreams into reality.</p>
          </div>
          <div className="premiumFeature">
            <h3>GPT-4 Support</h3>
            <p>Take your conversations to the next level with the mind-blowing power of GPT-4, Open Ai's latest large-language-model.</p>
          </div>
          <div className="premiumFeature">
            <h3>Write Better Code, Faster</h3>
            <p>Just ask your Ai for some programming help, and maximimze your coding productivity with Chat-GPT's brilliant code-writing assistance.</p>
          </div>
          <div className="premiumFeature">
            <h3>Create Lifelike Speech in a plethora of voices</h3>
            <p>You can even create your own voice models from your own recordings.</p>
          </div>
          <div className="premiumFeature">
            <h3>Meet your Personal DJ</h3>
            <p>Chimera can intelligently suggest songs, artists, and albums that may suit your fancy, and even play them on Spotify for you!<br /><u>Spotify Premium Required</u></p>
          </div>
        </div>
        <h2 className="featureSectionHeader">Planned Features</h2>
        <h3 className='featureSectionDescription'>Artificial Intelligence is advancing at an incredible pace. We're working to bring these features and more to Chimera as soon as possible.</h3>
        <div className="featuresWrapper">
          <div className="premiumFeature">
            <h3>Text-To-3D Model</h3>
            <p>Create your own 3D models using advanced AI generation.</p>
          </div>
          <div className="premiumFeature">
            <h3>Text-To-Music</h3>
            <p>Create music in any genre from a prompt.</p>
          </div>
          <div className="premiumFeature">
            <h3>Text-To-Video</h3>
            <p>Create videos from a text prompt, and eventually, fully fledged movies!</p>
          </div>
          <div className="premiumFeature">
            <h3>Avatar Store</h3>
            <p>Get new Avatars for your Chimera. Including animations to bring your AI to life!</p>
          </div>
        </div>
        {!props.user && <h2>All Aboard the AI Revolution! Sign up for Free Today!</h2>}
        <br />
        <br />
        {!props.user && <NavLink to="/signup" className="NavbarLink">Get Started</NavLink>}
        {props.user && <NavLink to="https://google.com" className="NavbarLink">Download Chimera</NavLink>}

      </div>
    </div>
  )
}
export default Home;