import logo from './logo.svg';
import './App.css';
import { useState, useEffect } from 'react';
import React from 'react';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc } from 'firebase/firestore';
import { useDocumentOnce } from 'react-firebase-hooks/firestore'

// Import firebase/auth
import { useAuthState, useCreateUserWithEmailAndPassword, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
// Import Other stuff
import { useNavigate, useLocation } from 'react-router-dom';
import AnimatedRoutes from './components/AnimatedRoutes';
import Navbar from './pages/Navbar';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMPCts7izMOfC0ayy0vAhMxuwqiXp4KMg",
  authDomain: "arizona-api-bba50.firebaseapp.com",
  projectId: "arizona-api-bba50",
  storageBucket: "arizona-api-bba50.appspot.com",
  messagingSenderId: "25440470052",
  appId: "1:25440470052:web:58148ee5e1fce3578584b1",
  measurementId: "G-G7XEMTKNFS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);


function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      if (location.pathname !== "/login" && location.pathname !== "/signup" && location.pathname !== "/" && location.pathname !== "/spotifyAuth") {
        if(location.pathname === "/dashboard"){
          navigate("/login")
        }else{
          navigate("/");
        }
      }
    }else{
      console.log(user)
    }
  }, [loading, auth, user, location])
  if (loading) {
    return (
      <div className="CenteredWrapper">
        <h1>Loading...</h1>
      </div>
    )
  }

  const logout = () => {
    signOut(auth);
  };

  return (
    <div>
      <Navbar navigate={navigate} location={location} user={user} loading={loading} error={error} logout={logout} />
      <AnimatedRoutes app={app} loading={loading} user={user} navigate={navigate} logout={logout} auth={auth} />
    </div>
  )
}

export default App;