import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from "framer-motion"

function SpotifyAuthPage() {
    const location = useLocation();
    const [authCode, setAuthCode] = React.useState(null);
    const [accessToken, setAccessToken] = React.useState(null);
    const [refreshToken, setRefreshToken] = React.useState(null);
    const [waitingForTokens, setWaitingForTokens] = React.useState(true);
    const client_id = "eb0929c190354d7ea0b7e8a065ad68ed"
    const [authError, setAuthError] = React.useState(null);
    const [codeFoundInURL, setCodeFoundInURL] = React.useState(false);
    function generateRandomString(length) {
        let text = '';
        const possible =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    const redirect_uri = (window.location.hostname === "localhost") ? "http://localhost:3000/spotifyAuth" : "https://chimera-app.com/spotifyAuth";
    async function generateCodeChallenge(codeVerifier) {
        const digest = await crypto.subtle.digest(
            'SHA-256',
            new TextEncoder().encode(codeVerifier),
        );

        return btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');
    }

    function generateUrlWithSearchParams(url, params) {
        const urlObject = new URL(url);
        urlObject.search = new URLSearchParams(params).toString();

        return urlObject.toString();
    }
    async function openAuthCodeWindow() {
        const codeVerifier = generateRandomString(75);
        console.log("Code verifier: " + codeVerifier)
        // Save codeVerifier to local storage
        window.localStorage.setItem('code_verifier', codeVerifier);
        generateCodeChallenge(codeVerifier).then(codeChallenge => {
            let state = generateRandomString(16);
            let args = new URLSearchParams({
                response_type: 'code',
                client_id: client_id,
                scope: 'user-modify-playback-state user-read-currently-playing user-read-playback-state user-top-read playlist-read-private',
                redirect_uri: 'http://localhost:3000/spotifyAuth',
                code_challenge_method: 'S256',
                code_challenge: codeChallenge,
                state: state
            })
            console.log("Opening web browser to authorize spotify")
            window.location.href = "https://accounts.spotify.com/authorize?" + args;
        })
    }

    useEffect(() => {
        // This should only fire on initial page load
        // Print useeffect fired and print the time in a time string

        try {
            const urlParams = new URLSearchParams(location.search);
            const foundCode = urlParams.get('code');
            console.log('Found Code:', foundCode);

            if (foundCode) {
                window.localStorage.setItem('authCode', foundCode)
                setAuthCode(foundCode);
                setCodeFoundInURL(true);
                // Wait a second to get the access token
                getAccessAndRefreshTokens();
            } else {
                // Remove the code_verifier, authCode, and tokens from local storage
                window.localStorage.removeItem('code_verifier');
                window.localStorage.removeItem('authCode');
                window.localStorage.removeItem('accessToken');
                window.localStorage.removeItem('refreshToken');
                setTimeout(() => {
                    openAuthCodeWindow();
                }, 2000);
            }
        } catch (error) {
            console.error('Error in redirect:', error);
        }
    }, []);
    // useEffect on startup, check for code in url
    const goToApp = () => {
        const savedAccessToken = localStorage.getItem('accessToken');
        const savedRefreshToken = localStorage.getItem('refreshToken');
        console.log("Access token is: " + savedAccessToken)
        console.log("Refresh token is: " + savedRefreshToken)
        console.log("Go to app, info is" + `arizona://spotifyAuth?code=${authCode}accessToken=${savedAccessToken}refreshToken=${savedRefreshToken}`)
        window.location.href = `arizona://spotifyAuth?code=${authCode}accessToken=${savedAccessToken}refreshToken=${savedRefreshToken}`;
    };

    const getAccessAndRefreshTokens = async () => {
        console.log("We're attempting to get the initial token data")
        let codeVerifier = window.localStorage.getItem('code_verifier');
        console.log("Code verifier: " + codeVerifier)
        let savedAuthCode = window.localStorage.getItem('authCode');
        let body = new URLSearchParams({
            client_id: client_id,
            grant_type: 'authorization_code',
            code: savedAuthCode,
            redirect_uri: "http://localhost:3000/spotifyAuth",
            code_verifier: codeVerifier
        });

        console.log("Body is: " + body)


        const tokenResponse = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: body
        });


        console.log(tokenResponse);
        const data = await tokenResponse.json();
        if (!tokenResponse.ok) {
            console.log("Error in token response")
            setAuthError(data.error + ": " + data.error_description + "\nTry to authorize again in the app.")
            return;
        }
        console.log(data);
        console.log(data)
        var foundAccessToken = data.access_token;
        var foundRefreshToken = data.refresh_token;
        localStorage.setItem('accessToken', foundAccessToken);
        localStorage.setItem('refreshToken', foundRefreshToken);
        console.log("Access token is: " + data.access_token)
        console.log("Refresh token is: " + data.refresh_token)
        setAccessToken(foundAccessToken);
        setRefreshToken(foundRefreshToken);
        setWaitingForTokens(false);
        // clear search query params in the url
        // window.history.replaceState({}, document.title, '/spotifyAuth');
    }

    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="TokenUsageDataContainer"
        >
            {/* <h1>Spotify Auth Page</h1> */}
            {/* If no code found in url, say "Redirecting to Spotify to Authorize" */}
            {/* If there is an auth error, show the error */}
            {authError ? (
                <p>Auth Error: {authError}</p>
            ) : null}

            {/* If accessToken and refreshToken are not null, show the gotoAppButton */}
            {accessToken && refreshToken ? (
                <button className="DashboardButton" onClick={goToApp}>Spotify Authorized Successfully! Click here to Finishing Connecting Spotify.</button>
            ) : (
                <h1>Redirecting to Spotify...</h1>
            )}

        </motion.div>
    );
}

export default SpotifyAuthPage;