import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import { NavLink, useNavigate } from 'react-router-dom';
import { useUpdatePassword, useSendPasswordResetEmail } from 'react-firebase-hooks/auth';

function ChangePasswordPage(props) {
    const [email, setEmail] = useState("")
    const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(props.auth)
    const [emailSentSuccesfully, setEmailSentSuccesfully] = useState(false)
    const actionCodeSettings = {
        url: 'https://chimera-app.com/login',
    }
    useEffect(() => {
        setEmail(props.auth.currentUser.email)
    }, [props.auth])
    const ConfirmSendPasswordResetEmail = async () => {
        console.log("Sending Password Reset Email")
        const success = await sendPasswordResetEmail(email, actionCodeSettings);
        if (success) {
            console.log(success)
            setEmailSentSuccesfully(true)
        }
    }
    if (emailSentSuccesfully) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Password Reset Email Sent</h1>
                <NavLink to="/dashboard" className="DashboardButton">Back To Dashboard</NavLink>
            </motion.div>
        )
    }
    if (sending) {
        return (
            <motion.div
                initial={{ filter: "blur(10px)", opacity: 0 }}
                animate={{ filter: "blur(0px)", opacity: 1 }}
                exit={{ filter: "blur(10px)", opacity: 0 }}
                transition={{ duration: 1, type: "easeInOut" }}
                className="CenteredWrapper"
            >
                <h1>Sending Password Reset Email</h1>
            </motion.div>
        )
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <div>
                <h1>Update Password</h1>
                <h3>{error}</h3>
                <div className="LineBreak"></div>

                <button className="DashboardButton" onClick={ConfirmSendPasswordResetEmail}>Send Change Password Email</button>
                <div className="LineBreak"></div>
                <NavLink to="/dashboard" className="DashboardButton">Back To Dashboard</NavLink>
            </div>
        </motion.div>
    )
}

export default ChangePasswordPage;