import React, { useState } from 'react';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { motion } from "framer-motion"

const PUBLIC_KEY = "pk_test_51MnIsOF9zYP6DsBOCK9al1tdeaCVwAoPfMpnFIffwMvGGEDoHxiFPUQcwTY4wscGPeaWwmFSntugibw3NVhYydT600PYDUMhBd";
function BuyTokens(props) {
    const functions = getFunctions(props.app);
    const [executeCallable, executing, error] = useHttpsCallable(functions, 'createPurchaseCheckoutSession');
    const [quantity, setQuantity] = useState(1);

    const handleSubmit = async (e) => {
        e.preventDefault();
        CreateCheckoutSession();
    };

    const CreateCheckoutSession = async () => {
        if(window.location.hostname === 'localhost'){connectFunctionsEmulator(functions, 'localhost', 5001);}
        const createCheckSessionURLRequest = await executeCallable({ quantity: quantity }).then((result)=>{return result.data})
        // Redirect to checkoutSessionUrlRequest.checkout_url
        console.log(createCheckSessionURLRequest)
        window.location.href = createCheckSessionURLRequest.checkout_url
        
    }
    if (executing) {
        return(
            <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className="CenteredWrapper"
        >
            <h1>Heading to Checkout...</h1>
        </motion.div>
        )
    }
    return (
        <motion.div
            initial={{ filter: "blur(10px)", opacity: 0 }}
            animate={{ filter: "blur(0px)", opacity: 1 }}
            exit={{ filter: "blur(10px)", opacity: 0 }}
            transition={{ duration: 1, type: "easeInOut" }}
            className='ScrollableWrapper'
        >
            <form onSubmit={handleSubmit}>
                <h1>Buy Tokens</h1>
                <h3>Here you can Purchase Tokens to spend on Chimera features.</h3>
                <h3>100,000 Tokens is $2.</h3>
                <h3>The Average Chimera Chat Response costs about 300 tokens.</h3>
                <h3>Once you run out of tokens, you'll need to buy more to continue using these features.</h3>
                <h3>To Automate this process, we recommend trying out a Pay-As-You Go Subscription, where your tokens can automatically be refilled up to your set monthly maximum.</h3>
                <label>
                    <h2>Quantity</h2>
                    <input
                        type="range"
                        min="1"
                        max="10"
                        step="1"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        required
                    />
                    <h3>{(quantity * 100000).toLocaleString()} Tokens for ${quantity * 2}</h3>
                </label>
                <label><h2>Terms and Conditions</h2>
                    <textarea multiline={"true"} disabled={true} value={`Terms and Conditions for Usage-Based Subscription Service

Acceptance of Terms
By using the Usage-Based Subscription Service ("Service"), you ("User") agree to be bound by these Terms and Conditions ("Terms"). The Service is provided by [Company Name] ("Company"). The Company reserves the right to update these Terms at any time without notice to the User.

Subscription and Billing
2.1. The User agrees to pay $2 for every 100,000 usage tokens purchased. The User may choose to enable an automatic refill option, which will result in the User being automatically charged $2 for 100,000 additional tokens whenever a call to the API requires more tokens than are currently available in the User's account.

2.2. If the User chooses not to enable the automatic refill option, the User may purchase additional tokens manually by clicking the "Buy Tokens" button on the "buy-tokens" page of the Company's website or within the Company's app. Tokens will be available for use immediately upon successful payment.

2.3. The User's subscription will continue until canceled. To cancel the subscription, the User must follow the cancellation procedure specified on the Company's website or in the app. Upon cancellation, the User will be charged for any tokens purchased but not used.

2.4. Unused tokens will remain tied to the User's account and may be used as normal until they are depleted.

Token Usage and Refunds
3.1. Tokens purchased through the Service are non-refundable, except as required by law. The User agrees that once tokens have been purchased, they cannot be refunded, transferred, or exchanged for cash or any other form of compensation.

3.2. Any unused tokens at the end of the month will remain tied to the User's account and may be used as normal.

Termination
The Company reserves the right to terminate the User's access to the Service at any time, with or without cause or notice, effective immediately. Upon termination, the User will be charged for any tokens purchased but not used.

Liability
The User agrees that the Company's entire liability, and the User's exclusive remedy, for any disputes arising from the use of the Service, shall be limited to the amount the User paid for the Service during the most recent billing period.

Governing Law
These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which the Company is incorporated, without regard to its conflict of law provisions.

Severability
If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.

Entire Agreement
These Terms constitute the entire agreement between the User and the Company with respect to the subject matter hereof and supersede and replace all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter. Any waiver of any provision of these Terms will be effective only if in writing and signed by the Company.`}>
                    </textarea>
                </label>
                <label className='tacCheckbox'>
                    <h2>I Agree To the Terms and Conditions</h2>
                    <input type="checkbox" required />
                </label>

                <button className='DashboardButton'>Checkout</button>
            </form>
        </motion.div>
    );
}

export default BuyTokens;